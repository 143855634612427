h1 {
    padding-top: 90px;
    text-align: center;
    margin-bottom: 20px;
    color: #139b51;
    font-size: 30px;
}

.secao-principal-sobre {
    background-color: #f9f6ee;
}

.container-cards {
    margin-bottom: 20px;
}

.secao-card {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: 100%;
    height: 400px;
    background-color: #D4E9E2;
}

.secao-card img {
    width: 50%;
    height: auto;
    object-fit: cover;

}

.texto-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
}

.texto-cards h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.texto-cards p {
    font-size: 18px;
    padding: 0 48px;
    line-height: 26px;
    margin-top: 10px;

}

@media(max-width: 1323px) {
    .secao-main-sobre {
        padding: 15px;
    }
}

@media(max-width: 900px) {
    .secao-card {
        flex-direction: column;
        height: auto;
    }

    .secao-card img {
        width: 100%;
        max-height: 250px;
    }

    .texto-cards {
        width: 100%;
        height: 300px;
    }

    .secao-vinagrete {
        flex-direction: column-reverse;
    }

    .secao-ambiente {
        flex-direction: column-reverse;
    }
}

@media(max-width: 550px) {
    .texto-cards h2{
        font-size: 25px;
    }

    .texto-cards p{
        font-size: 15px;
    }
}
