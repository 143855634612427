footer{
    position: relative;
    padding: 20px;
    line-height: 34px;
    background-color: #f9f6ee;
    color: white;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    border-top: 1px solid gainsboro;    
    margin-top: 20px;
}

footer span {
    color: goldenrod;
}

@media (max-width:530px) {
    footer {
        font-size: 12px;
    }
    
}
