.card {
    width: 250px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border: 2px solid goldenrod;
    border-radius: 8px;
    background-color: white;
}

.card figure {
    height: 250px;
    
}

.card figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.container-informacoes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    flex: 1 1 150px;
}

/* .container-informacoes div:nth-child(1) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
  } */

  .container-informacoes div small {
    color: #139b51;
  }

  .container-informacoes div:nth-child(2) {
    text-align: right;
    font-weight: 600;
  }

  @media (max-width: 600px) {
    .card {
      width: 100%;
      flex-direction: row;
      margin-bottom: -20px;
    }

    .card figure {
      width: 100px;
      height: auto;
      
    }

    .card p {
      font-size: 12px;
    }

    .card h3 {
      font-size: 16px;
    }
  }