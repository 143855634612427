* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: black;
}

.limitar-secao {
  width: 100%;
  max-width: 1324px;
  margin: 0 auto;
}

