.secao-banner {
    height: 80vh;
    padding-top: 70px;
    display: flex;
    position: relative;

}

.imagem-de-fundo {
    width: 50%;
    background-image: url('../../img/food2.JPEG');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 20px;
}

.texto-secaoBanner {
    width: 50%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f9f6ee;
}

.texto-secaoBanner h2 {
    font-size: 60px;
    font-weight: bold;
    color: #111111;
    font-family: 'Palanquin Dark', sans-serif;
    letter-spacing: 2px;
}

.texto-secaoBanner span {
    color: #fbcb0c;
}

.texto-secaoBanner p {
    font-size: 30px;
}

.secao-icone img {
    margin-top: 25px;
    height: 40px;
}

.secao-icone img:hover {
    transform: translateY(-8px);
    transition: all 0.8s ease-in-out;
}

.icone {
    max-width: auto;
    height: 30px;
    margin-right: 20px;
    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    /* Sombra suave para a direita */
}

.secao-link-cardapio {
    border-radius: 20px;
    width: 180px;
    height: 75px;
    font-weight: bold;
    margin: 30px 0;
    background-color: #4CAF50;
}

.cardapio-botao {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 25px;
    text-decoration: none;
    letter-spacing: 2px;
    color: white;
   
}

@media screen and (max-width: 768px) {
    .imagem-de-fundo {
        width: 0;
    }

    .texto-secaoBanner {
        width: 100%;

    }

    .secao-banner {
        height: 450px;
    }

    .texto-secaoBanner p {
        font-size: 25px;
    }

    .texto-secaoBanner h2 {
        font-size: 50px;

    }
}

@media screen and (max-width: 400px) {
    .texto-secaoBanner p {
        font-size: 20px;
    }
}