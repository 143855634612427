
.secao-cards {
    max-width: 1324px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 0 auto;
}

.container-principal div h2 {
    font-family: "Poppins", sans-serif;
    color: #139b51;
    font-size: 20px;
    text-align: center; 
    margin: 20px;   
    letter-spacing: 5px;
}

.container-principal div h4 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    margin-bottom: 70px;
}

.container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px;
    gap: 40px;
}

.container-principal {
    padding-top: 90px;
    background-color: #f9f6ee;
}



@media (max-width: 600px) {
    .container-cards {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 750px){
    .container-principal div h4{
        font-size: 30px;
    }
}
@media (max-width: 500px){
    .container-principal div h4{
        font-size: 23px;
        margin-bottom: 10px;
    }
    .container-principal div h2{
        font-size: 20px;
    }
}